/** @jsx jsx */
import React from "react";
import { jsx, Container } from "theme-ui";
import Map from 'react-map-gl';
import Layout from "../components/Layout";
import "../styles/mapbox-gl.css";
import "./static/style.less";

class Maps extends React.PureComponent {
    render() {
    return (
      <Layout>
        <Container sx={{mb: '60px'}}>
            <Map
                 initialViewState={{
                    longitude: -100,
                    latitude: 40,
                    zoom: 3.5
                  }}
                sx={{width: '100%', height: 700}}
                mapStyle="mapbox://styles/greatexpectations/cla8f0cjw002915ocruprjiwn"
                mapboxAccessToken={`${process.env.GATSBY_MAPS_ACCESS_TOKEN}`}
            /> 
        </Container>
      </Layout>
    );
  }
}
export default Maps;
